<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="usersProvider"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      class="position-relative"
      responsive="lg"
      primary-key="id"
    >
      <template #cell(username)="{item}">
        {{ item.profile ? `${item.profile.first_name} ${item.profile.last_name}` : '' }}
      </template>
      <template #cell(relations)="{item}">
        <span class="text-capitalize">{{ item.relations.length !==0 ? item.relations.join(", ") : '' }}</span>
      </template>
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'mobile-users-view', params: { id: item.id} }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50"> View </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'

export default {
  props: {
    usersProvider: { type: Function, default: () => null },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'username', label: 'Name' },
        { key: 'email' },
        { key: 'phone_number', label: 'Phone' },
        { key: 'relations' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/user/`,
    }
  },
  setup() {
    const { deleteRecord, table } = deleteEntityComposition()
    return {
      deleteRecord, table,
    }
  },
}
</script>
<style lang='scss' scoped>
</style>
