<template>
  <div>
    <!-- Filter BY Name, Email, Phone -->
    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col
              md="6"
            >
              <search
                placeholder="Name, Email, Phone"
                :search-query.sync="search"
                @refresh="refreshTable"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <b-button
                variant="success"
                @click="exportUsers()"
              >
                Export
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <b-row>
      <b-col>
        <user-table
          ref="parentTable"
          v-bind="{usersProvider,pagination}"
        />
        <pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import Search from '@/common/components/common/Table/Search.vue'
import UserTable from '@/common/components/MobileUser/UserTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'MobileUsersList',
  components: {
    UserTable,
    Search,
    Pagination,
    SearchButton,
  },

  data() {
    return {
      inpoID: this.$store.getters['mainEntity/getEntityId'],
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      search: '',
      loading: true,
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 15,
      },
    }
  },
  methods: {
    usersProvider() {
      const promise = this.$portalUsers.post(`internalops/get-entity-users?page=${this.pagination.currentPage}`, {
        entity_id: this.inpoID,
        search: this.search,
      })
      return promise.then(res => {
        const users = res.data.data
        this.pagination.perPage = res.data.pagination.per_page
        this.pagination.totalRows = res.data.pagination.total
        return users || []
      }).catch(() => [])
        .finally(() => {
          this.loading = false
        })
    },
    exportUsers() {
      this.$portalUsers.post('internalops/export-entity-users', {}, {
        responseType: 'blob',
        params: {
          entity_id: this.$store.getters['mainEntity/getEntityId'],
        },
      }).then(res => {
        const blob = new Blob([res.data])
        const filename = `${this.$moment().format('YYYY-MM-DD')}_users.xlsx`
        saveAs(blob, filename)
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
</style>
